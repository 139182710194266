export default {
    main: [
        {
            type: 'link',
            label: 'Inicio',
            to: '/'
        },
        {
            type: 'link',
            label: 'Nosotros',
            to: '/nosotros/'
        },
        {
            type: 'link',
            label: 'Blog',
            to: 'https://blog.alanube.co/'
        },
    ]
}